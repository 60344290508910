import React, { useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { Layout, Menu, Popconfirm, Popover } from "antd";
import { MenuOutlined, UserOutlined } from "@ant-design/icons";

import { privateRoutes } from "../routes/routes";
import constant from "../data/constant";
import { useLogout } from "../recoil/auth";
import useSWR from "swr";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const ServiceWrapper = ({ children }) => {
  const logout = useLogout();
  const location = useLocation();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isVisibleProfilePopover, setIsVisibleProfilePopover] = useState(false);

  const pathName = location.pathname;
  const rootPath = pathName.substring(0, pathName.indexOf("/", 2));

  const openKey = pathName.split("/").length > 2 ? rootPath : pathName;

    const { data } = useSWR("/admin/menu");
    const menu = data?.data;

  return (
    <Layout>
      <Sider
        collapsed={isCollapsed}
        onCollapse={(collapsedState) => setIsCollapsed(collapsedState)}
        width={260}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          background: "white",
          borderRight: "1px solid #f0f0f0",
        }}
      >
        {!isCollapsed && (
          <LogoImage src={require("../assets/logo.png").default} />
        )}
        <Menu
          mode="inline"
          activeKey={location.pathname}
          defaultOpenKeys={[openKey]}
          selectedKeys={[location.pathname]}
        >
          {privateRoutes.map((parentRoute) =>
              (Object(parentRoute).level >= localStorage.getItem("admin_type") && mainMenuCheck(parentRoute?.menu, menu)) && (
                  parentRoute.children ? (
                      <SubMenu
                          key={parentRoute.path}
                          icon={Object(parentRoute.sidebar).icon}
                          title={Object(parentRoute.sidebar).label}
                          style={{ fontSize: 14 }}
                      >
                          {parentRoute.children.map(
                              ({ visible = true, ...childrenRoute }) =>
                                  (visible && subMenuCheck(childrenRoute?.menu, menu)) && (
                                      <Menu.Item
                                          key={`${parentRoute.path}${childrenRoute.path}`}
                                          icon={Object(childrenRoute.sidebar).icon}
                                      >
                                          <NavLink
                                              to={`${parentRoute.path}${childrenRoute.path}`}
                                              className="nav-text"
                                              style={{ fontSize: 14 }}
                                          >
                                              {Object(childrenRoute.sidebar).label}
                                          </NavLink>
                                      </Menu.Item>
                                  )
                          )}
                      </SubMenu>
                  )  : (
                      <Menu.Item
                          key={parentRoute.path}
                          icon={Object(parentRoute.sidebar).icon}
                      >
                          <NavLink
                              to={parentRoute.path}
                              className="nav-text"
                              style={{ fontSize: 14 }}
                          >
                              {Object(parentRoute.sidebar).label}
                          </NavLink>
                      </Menu.Item>
                  )
              )
          )}
        </Menu>
      </Sider>
      <Layout
        style={{
          marginLeft: isCollapsed ? 80 : 260,
          transition: "all 0.2s",
          minHeight: "100vh",
          backgroundColor: "white",
        }}
      >
        <Header
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0px 15px",
            backgroundColor: "white",
            boxShadow: "0 2px 8px #f0f1f2",
          }}
        >
          <MenuOutlined
            style={{ fontSize: 20 }}
            onClick={() => setIsCollapsed((prevState) => !prevState)}
          />
          <Popover
            trigger="click"
            placement="bottomRight"
            content={
              <PopoverContents>
                <Popconfirm
                  title="로그아웃 하시겠습니까?"
                  onConfirm={logout}
                  okText="확인"
                  cancelText="취소"
                >
                  <span style={{ color: "black", cursor: "pointer" }}>
                    로그아웃
                  </span>
                </Popconfirm>
              </PopoverContents>
            }
            visible={isVisibleProfilePopover}
            onVisibleChange={(visibleState) =>
              setIsVisibleProfilePopover(visibleState)
            }
          >
            <UserOutlined />
          </Popover>
        </Header>
        <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
          {children}
        </Content>
        <Footer style={{ textAlign: "center" }}>{constant.footerText}</Footer>
      </Layout>
    </Layout>
  );
};

const ProfileImage = styled.img`
  width: 25px;
  height: 25px;
  border-radius: 12.5px;
  cursor: pointer;
`;

const PopoverContents = styled.div`
  width: 150px;
`;

const LogoImage = styled.img`
  width: 100%;
  font-family: "Nanum Gothic", sans-serif;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  padding: 40px;
`;

function mainMenuCheck(menuName, menu)
{
    if (menuName === 'admin_user') {
        return (menu?.admin_user === 1 || menu?.admin_qna === 1);
    } else if (menuName === 'admin_store') {
        return (menu?.admin_store === 1);
    } else if (menuName === 'admin_contents') {
        return (menu?.admin_contents === 1 || menu?.admin_contents_category === 1);
    } else if (menuName === 'admin_product') {
        return (menu?.admin_product === 1);
    } else if (menuName === 'admin_order') {
        return (menu?.admin_order === 1);
    } else if (menuName === 'admin_notice') {
        return (menu?.admin_notice === 1 || menu?.admin_event === 1);
    } else if (menuName === 'admin_coupon') {
        return (menu?.admin_coupon === 1);
    } else if (menuName === 'admin_site') {
        return (menu?.admin_banner === 1 || menu?.admin_footer === 1 || menu?.admin_terms === 1 || menu?.admin_api === 1);
    } else if (menuName === 'admin_coupon') {
        return (menu?.admin_coupon === 1);
    } else if (menuName === 'admin_admin') {
        return (menu?.admin_admin === 1 || menu?.admin_history === 1);
    } else if (menuName === 'admin_stats') {
        return (menu?.admin_product_stats === 1 || menu?.admin_contents_stats === 1 || menu?.admin_search_stats === 1);
    }
}

function subMenuCheck(menuName, menu)
{
    if (menuName === 'admin_user') {
        return menu?.admin_user === 1;
    } else if (menuName === 'admin_qna') {
        return menu?.admin_qna === 1;
    } else if (menuName === 'admin_store') {
        return menu?.admin_store === 1;
    } else if (menuName === 'admin_contents') {
        return menu?.admin_contents === 1;
    } else if (menuName === 'admin_contents_category') {
        return menu?.admin_contents_category === 1;
    } else if (menuName === 'admin_product') {
        return menu?.admin_product === 1;
    } else if (menuName === 'admin_order') {
        return menu?.admin_order === 1;
    } else if (menuName === 'admin_notice') {
        return menu?.admin_notice === 1;
    } else if (menuName === 'admin_event') {
        return menu?.admin_event === 1;
    } else if (menuName === 'admin_coupon') {
        return menu?.admin_coupon === 1;
    } else if (menuName === 'admin_banner') {
        return menu?.admin_banner === 1;
    } else if (menuName === 'admin_footer') {
        return menu?.admin_footer === 1;
    } else if (menuName === 'admin_terms') {
        return menu?.admin_terms === 1;
    } else if (menuName === 'admin_api') {
        return menu?.admin_api === 1;
    } else if (menuName === 'admin_admin') {
        return menu?.admin_admin === 1;
    } else if (menuName === 'admin_history') {
        return menu?.admin_history === 1;
    } else if (menuName === 'admin_product_stats') {
        return menu?.admin_product_stats === 1;
    } else if (menuName === 'admin_contents_stats') {
        return menu?.admin_contents_stats === 1;
    } else if (menuName === 'admin_search_stats') {
        return menu?.admin_search_stats === 1;
    }
}

export default ServiceWrapper;
