import React from "react";
import loadable from "@loadable/component";
//antd에서 아이콘 가져오기
import {
  UserOutlined,
  ApartmentOutlined,
  ShopOutlined,
  PicLeftOutlined,
  SettingOutlined,
  CreditCardOutlined,
  ShoppingCartOutlined,
  UsergroupAddOutlined,
  LineChartOutlined,
  FormOutlined
} from "@ant-design/icons";
//회원 전용 루트
import { PUBLIC_ROUTE } from "./routes.constants";
import { Redirect } from "react-router";

//로그인 없이 이용가능 페이지
export const publicRoutes = [
  //첫시작 로그인으로 설정 => ('/' 해당 url 사용 안함)
  {
    exact: true,
    path: PUBLIC_ROUTE.ROOT,
    component: () => {
      if (localStorage.getItem("token")) {
        return <Redirect to="/user/index" />
      } else {
        return <Redirect to="/login" />
      }
    },
  },
  {
    exact: true,
    path: PUBLIC_ROUTE.LOGIN,
    component: loadable(() => import("../pages/auth/Login")),
  },
];

//로그인 시에만 이용가능 페이지
export const privateRoutes = [
  {
    exact: true,
    path: "/user",
    level: 2,
    menu: 'admin_user',
    sidebar: {
      icon: <UserOutlined />,
      label: "회원 관리",
    },
    children: [
      {
        exact: true,
        menu: 'admin_user',
        path: "/index",
        sidebar: {
          label: "회원 목록",
        },
        component: loadable(() => import("../pages/user/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/user/Detail")),
      },
      {
        exact: true,
        menu: 'admin_qna',
        path: "/qna/index",
        sidebar: {
          label: "1:1 문의 관리",
        },
        component: loadable(() => import("../pages/qna/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/qna/detail/:id",
        component: loadable(() => import("../pages/qna/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/store",
    menu: 'admin_store',
    level: 2,
    sidebar: {
      icon: <ApartmentOutlined />,
      label: "입점몰 관리",
    },
    children: [
      {
        exact: true,
        menu: 'admin_store',
        path: "/index",
        sidebar: {
          label: "입점몰 목록",
        },
        component: loadable(() => import("../pages/store/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/store/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/modify/:id",
        component: loadable(() => import("../pages/store/Modify")),
      },
    ],
  },
  {
    exact: true,
    path: "/contents",
    menu: 'admin_contents',
    level: 2,
    sidebar: {
      icon: <FormOutlined />,
      label: "컨텐츠 관리",
    },
    children: [
      {
        exact: true,
        menu: 'admin_contents',
        path: "/index",
        sidebar: {
          label: "컨텐츠 목록",
        },
        component: loadable(() => import("../pages/contents/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/contents/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/contents/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/modify/:id",
        component: loadable(() => import("../pages/contents/Modify")),
      },
      {
        exact: true,
        visible: false,
        path: "/item/register/:id",
        component: loadable(() => import("../pages/contents/item/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/item/modify/:id",
        component: loadable(() => import("../pages/contents/item/Modify")),
      },
      {
        exact: true,
        menu: 'admin_contents_category',
        path: "/category/index",
        sidebar: {
          label: "컨텐츠 분류 관리",
        },
        component: loadable(() => import("../pages/contents/category/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/category/register",
        component: loadable(() => import("../pages/contents/category/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/category/modify/:id",
        component: loadable(() => import("../pages/contents/category/Modify")),
      },
    ],
  },
  {
    exact: true,
    menu: 'admin_product',
    path: "/product",
    level: 3,
    sidebar: {
      icon: <ShopOutlined />,
      label: "상품 관리",
    },
    children: [
      {
        exact: true,
        menu: 'admin_product',
        path: "/index",
        sidebar: {
          label: "상품 목록",
        },
        component: loadable(() => import("../pages/product/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/product/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/modify/:id",
        component: loadable(() => import("../pages/product/Modify")),
      },
    ],
  },
  {
    exact: true,
    path: "/order",
    menu: 'admin_order',
    level: 3,
    sidebar: {
      icon: <ShoppingCartOutlined />,
      label: "주문 관리",
    },
    children: [
      {
        exact: true,
        menu: 'admin_order',
        path: "/index",
        sidebar: {
          label: "주문 목록",
        },
        component: loadable(() => import("../pages/order/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/order/Detail")),
      },
    ],
  },
  {
    exact: true,
    menu: 'admin_notice',
    path: "/notice",
    level: 2,
    sidebar: {
      icon: <PicLeftOutlined />,
      label: "공지사항/이벤트 관리",
    },
    children: [
      {
        exact: true,
        menu: 'admin_notice',
        path: "/index",
        sidebar: {
          label: "공지사항 목록",
        },
        component: loadable(() => import("../pages/notice/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/notice/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/modify/:id",
        component: loadable(() => import("../pages/notice/Modify")),
      },
      {
        exact: true,
        menu: 'admin_event',
        path: "/event/index",
        sidebar: {
          label: "이벤트 목록",
        },
        component: loadable(() => import("../pages/event/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/event/register",
        component: loadable(() => import("../pages/event/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/event/modify/:id",
        component: loadable(() => import("../pages/event/Modify")),
      },
    ],
  },
  {
    exact: true,
    menu: 'admin_coupon',
    path: "/coupon",
    level: 2,
    sidebar: {
      icon: <CreditCardOutlined />,
      label: "쿠폰 관리",
    },
    children: [
      {
        exact: true,
        menu: 'admin_coupon',
        path: "/index",
        sidebar: {
          label: "쿠폰 목록",
        },
        component: loadable(() => import("../pages/coupon/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/coupon/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/modify/:id",
        component: loadable(() => import("../pages/coupon/Modify")),
      },
      {
        exact: true,
        visible: false,
        path: "/issue/:id",
        component: loadable(() => import("../pages/coupon/Issue/Issue")),
      },
      {
        exact: true,
        visible: false,
        path: "/issue/index/:id",
        component: loadable(() => import("../pages/coupon/Issue/List")),
      },
    ],
  },
  {
    exact: true,
    menu: 'admin_site',
    path: "/site",
    level: 2,
    sidebar: {
      icon: <SettingOutlined />,
      label: "사이트 관리",
    },
    children: [
      {
        exact: true,
        menu: 'admin_banner',
        path: "/banner/index",
        sidebar: {
          label: "배너 목록",
        },
        component: loadable(() => import("../pages/banner/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/banner/register",
        component: loadable(() => import("../pages/banner/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/banner/modify/:id",
        component: loadable(() => import("../pages/banner/Modify")),
      },
      {
        exact: true,
        menu: 'admin_footer',
        path: "/footer",
        sidebar: {
          label: "푸터 관리",
        },
        component: loadable(() => import("../pages/footer/List")),
      },
      {
        exact: true,
        menu: 'admin_terms',
        path: "/terms",
        sidebar: {
          label: "약관 관리",
        },
        component: loadable(() => import("../pages/terms/List")),
      },
      {
        exact: true,
        menu: 'admin_api',
        path: "/api",
        sidebar: {
          label: "API 관리",
        },
        component: loadable(() => import("../pages/api/List")),
      },
    ],
  },
  {
    exact: true,
    menu: 'admin_admin',
    path: "/admin",
    level: 2,
    sidebar: {
      icon: <UsergroupAddOutlined />,
      label: "관리자 설정",
    },
    children: [
      {
        exact: true,
        menu: 'admin_admin',
        path: "/index",
        sidebar: {
          label: "관리자 목록",
        },
        component: loadable(() => import("../pages/admin/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/admin/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/modify/:id",
        component: loadable(() => import("../pages/admin/Modify")),
      },
      {
        exact: true,
        menu: 'admin_history',
        path: "/history",
        sidebar: {
          label: "관리자 방문 로그",
        },
        component: loadable(() => import("../pages/admin/History")),
      },
    ],
  },
  {
    exact: true,
    menu: 'admin_stats',
    path: "/stat",
    level: 2,
    sidebar: {
      icon: <LineChartOutlined />,
      label: "통계",
    },
    children: [
      {
        exact: true,
        menu: 'admin_product_stats',
        path: "/product/day",
        sidebar: {
          label: "상품 통계 (일별)",
        },
        component: loadable(() => import("../pages/stat/product/Day")),
      },
      {
        exact: true,
        menu: 'admin_product_stats',
        path: "/product/time",
        sidebar: {
          label: "상품 통계 (시간대별)",
        },
        component: loadable(() => import("../pages/stat/product/Time")),
      },
      {
        exact: true,
        menu: 'admin_contents_stats',
        path: "/contents/day",
        sidebar: {
          label: "컨텐츠 통계 (일별)",
        },
        component: loadable(() => import("../pages/stat/contents/Day")),
      },
      {
        exact: true,
        menu: 'admin_contents_stats',
        path: "/contents/time",
        sidebar: {
          label: "컨텐츠 통계 (시간대별)",
        },
        component: loadable(() => import("../pages/stat/contents/Time")),
      },
      {
        exact: true,
        menu: 'admin_search_stats',
        path: "/search/product",
        sidebar: {
          label: "검색어 통계 (상품별)",
        },
        component: loadable(() => import("../pages/stat/search/Product")),
      },
      {
        exact: true,
        menu: 'admin_search_stats',
        path: "/search/time",
        sidebar: {
          label: "검색어 통계 (시간대별)",
        },
        component: loadable(() => import("../pages/stat/search/Time")),
      },
    ],
  },
];
